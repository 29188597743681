import NormalLayout from "../../../shared/layouts/NormalLayout/NormalLayout";




function About(){
    return(
        <NormalLayout>
        <h2>About page</h2>
        </NormalLayout>
    )
}
export default About;