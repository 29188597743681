import style from './NewUserCard.module.css';


function NewUserCard(){
    return(
        <>
        <div className={style.container}>
            <div className={style.header_profile}>
                {/* <img src='https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg' alt='pic' className={style.background_pic} /> */}
            </div>
            <div className={style.NewUserCard_profilesection}>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKjGLIBpT2G4YQxxnD1PAZNtIcssePTEWQxY1wjQYQzKC0O6IvKYD386GiKMd0-3TWM6o&usqp=CAU' alt='pic' className={style.NewUserCard_profile} />
            </div>
            <div className={style.NewUserCard_profileDetails}>
                    <h2 className={style.NewUserCardName}>John Smith</h2>
                    <p className={style.NewUserCardBio}>Human Resource Consultant at <br/>
                     Genpact</p>
            </div>
            <div className={style.followSection}>
                <button className={style.followBtn}>Follow</button>
            </div>
        </div>
        </>
    )
}
export default NewUserCard;