import style from "./Register.module.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postRegisterApi } from "../../../services/Register/register.api";
import { useState } from "react";


function Register() {
  const[isLoading,setIsLoading] = useState(false);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const backToLogin =()=>{
    navigate('/');
  }
  const onSubmit = (data) => {
    // console.log(data);
    setIsLoading(true);
    postRegisterApi({
      name: data.name,
      email: data.email,
      password: data.password,
    })
      .then((result) => {
        setIsLoading(false)
        console.log(result);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        alert("User already Exist");
      });
  };
  return (
    <>
      {/* <div className="regd-container">
        <div className="regd-main">
          <div className="style">
            <div>
              <div className="icon-div">
                <FaUserCircle className="register-icon" />
              </div>
              <h1 className="regd-header">Register here</h1>
            </div>
          </div>
          <form className="regd-form" onSubmit={handleSubmit(onSubmit)}>
            <label className="regd-label">Name</label>
            <input
              type="text"
              placeholder="Your Full Name"
              name="name"
              className="regd-input"
              {...register("name", { required: "Name is Required" })}
            />
            <br />
            <label className="regd-label">Phone</label>
            <input
              type="text"
              placeholder="Your Number"
              name="phone"
              className="regd-input"
              {...register("phone", { pattern: /^[0-9]{10}$/ })}
            />
            {errors.phone && <p className="error">Minimum required 10 digit</p>}
            <br />
            <label className="regd-label">Email</label>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              className="regd-input"
              {...register("email", { required: "Email is Required" })}
            />
            <br />
            {errors.email && <p className="error">{errors.email.message}</p>}
            <label className="regd-label"> Password</label>
            <input
              type="password"
              placeholder="password"
              name="password"
              className="regd-input"
              {...register("password", { required: "Password is Required" })}
            />
            <br />
            {errors.password && (
              <p className="error">{errors.password.message}</p>
            )}
             {!isLoading &&  <button className="regd-btn">
             Create Account
           </button>} 
           {isLoading && <button className="regd-btn" disabled> <FaSpinner className="spinner-icon"/> Loading..</button>}
          </form>
        </div>
      </div> */}
      <div className={style.container}>
          <div className={style.main_container}>
              <div className={style.left_child}></div>
              <div className={style.right_child}>
                <div className={style.header}>
                  <h2 className={style.header_text}>Create Your Free Account</h2>
                </div>
                <form className={style.registe_container} onSubmit={handleSubmit(onSubmit)}>
                      
                          <input
                              type="text"
                              placeholder="Your Full Name"
                              name="name"
                              className={style.input_container}
                              {...register("name", { required: "Name is Required" })}
                          />
                      <br/>
                      <br/>
                          
                          <input
                            type="text"
                            placeholder="Your Number"
                            name="phone"
                            className={style.input_container}
                            {...register("phone", { pattern: /^[0-9]{10}$/ })}
                          />
                              <br/>
                          {errors.phone && <p className={style.error}>Minimum required 10 digit</p>}
                      <br />
                          
                          <input
                            type="email"
                            placeholder="Email Address"
                            name="email"
                            className={style.input_container}
                            {...register("email", { required: "Email is Required" })}
                          />
                      <br />
                            {errors.email && <p className={style.error}>{errors.email.message}</p>}
                            <br/>
                          
                          <input
                            type="password"
                            placeholder="password"
                            name="password"
                            className={style.input_container}
                            {...register("password", { required: "Password is Required" })}
                          />
                          {errors.password && (<p className={style.error}>{errors.password.message}</p>)}

                          {!isLoading &&  <button className={style.regd_btn}>Create Account</button>} 
                          {isLoading && <button className={style.regd_btn} disabled><i class="fa fa-spinner fa-spin"></i> Loading..</button>}

                          <br/>
                          <p className={style.back_text} onClick={backToLogin}>Back to login</p>
                </form>
              </div>
          </div>
      </div>
    </>
  );
}
export default Register;
