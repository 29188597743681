import React,{useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { friendListApi } from '../../../services/UserFriendList/UserFriendList.api';
import NormalLayout from '../../../shared/layouts/NormalLayout/NormalLayout';
import { IMAGEURL } from '../../../utils/constants';
import style from './MyConnections.module.css';
import {getSingleUserApi } from '../../../services/GetSingleUserData/GetSingleUserData';



function MyConnections(){
    const [frinedListData,setFriendListData] = useState([]);
    const navigate = useNavigate();
    const getAllFriends =()=>{
        friendListApi().then((result)=>{
            // console.log(result.data.friends[0]._id);
            setFriendListData(result.data.friends);
        }).catch((error)=>{
            console.log(error);
        })
    }
    useEffect(()=>{
        getAllFriends();
    },[])
    const passArr = frinedListData.length;
    sessionStorage.setItem('myConnection', passArr);
    function viewProfile(_id){
        // console.log(_id);
        getSingleUserApi({_id:`${_id}`})
        .then((response)=>{
            console.log(response);
            navigate('/friend-profile', {state:{_id}})
        }).catch((error)=>{
            console.log(error);
        })
    }
    return(
        <NormalLayout>
            {frinedListData && <h5 className={style.header}>About {frinedListData.length}</h5>}
         <div className={style.container}>
            <div className={style.main_container}>
                {
                    frinedListData.map((data)=>{
                        // console.log(data._id)
                        return(
                            <>
                             <div className={style.friendlistSection} key={data._id}>
                                    <img src={`${IMAGEURL}/${data.profilePic.path}`}  alt='mic' className={style.friendListProfile} />
                                    <div className={style.friendlistBioSection}>
                                        <h3 className={style.userName} onClick={()=>viewProfile(data._id)}>{data.name}</h3>
                                        <p className={style.friendlistBio}>{data.positions[0]?.title} in {data.positions[0]?.industry}</p>
                                        <p className={style.friendlistaddress}>{data.positions[0]?.location}</p>
                                    </div>
                            </div>
                            <hr className={style.endline}/>
                            </>
                        );
                    })}
            </div>
        </div>
        </NormalLayout>
    )
}
export default MyConnections;