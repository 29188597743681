import React,{useState} from 'react';
import navStyle from './Header.module.css';
import { FiSearch} from "react-icons/fi";
import { NavLink} from 'react-router-dom';
import { IoHome } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
import { TbListDetails} from "react-icons/tb";
import { IoNotificationsSharp } from "react-icons/io5";
import DropdownButton from '../../shared/components/DropdownButton/DropdownButton'
import { IMAGEURL } from '../../utils/constants';
import anonymousImage from '../../assets/images/logos/anonymous.webp'



function Header(){
    const [token,setToken] = useState(localStorage.getItem('token'));
    const userData = JSON.parse(sessionStorage.getItem('userInfo'));

    // console.log(token)


    // useEffect(()=>{
    //      if( localStorage.getItem('token')){
    //         window.location.href=('/home')}
    // }, [])


    return(
        <>
        <nav className={navStyle.nav}>
            <div className={navStyle.logo}>
            <div className={navStyle.logoname}><h2 className={navStyle.logoTitle}>DevSocial</h2></div>
            <div className={navStyle.searchBar}>
            <div className={navStyle.searchBtn}>
            <FiSearch className={navStyle.searchicon}/>
            <input type='text' placeholder='Search' className={navStyle.search_filed}/>
            </div>
            </div>
            </div>
            <ul className={navStyle.navbar}>
                    {
                        token ?
                        <>
                        <li><NavLink to='/home'className={navStyle.navLink}><IoHome className={navStyle.navicon}/>Home</NavLink></li>
                        <li><NavLink to='/mynetwork' className={navStyle.navLink}><FaUserFriends className={navStyle.navicon}/>My Network</NavLink></li>
                        <li><NavLink to='/about' className={navStyle.navLink}><TbListDetails className={navStyle.navicon}/>About</NavLink></li>
                        <li><NavLink to='/notifications' className={navStyle.navLink}><IoNotificationsSharp className={navStyle.navicon}/>Notifications</NavLink></li>
                       <div className={navStyle.navuser}> <li className={navStyle.navLink} id={navStyle.navuserProfile}><img src={userData?.profilePic?.path? `${IMAGEURL}/${userData?.profilePic?.path}`:anonymousImage}
                         alt='pic' className={navStyle.navProfile} /><DropdownButton/></li></div>
                        </>
                        :
                        <>
                        <li><NavLink to='/' className={navStyle.navLink}>Login</NavLink></li>
                        </>
                    }
            </ul>
        </nav>
        </>
    )
}
export default Header;