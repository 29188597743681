import style from './Dropdown.module.css'
import React,{useState} from 'react';


function Dropdown(){
    const [isActive, setIsActive] = useState(false);
    const userData = JSON.parse(sessionStorage.getItem('userInfo'));

    // let navigate = useNavigate();s
    function toMyprofile(){
        // return <Navigate to='/my-profile'/>
        window.location.href=('/my-profile');
    }
    function forLogout(){
        // navigate('/')
        localStorage.clear();
        window.location.href=('/');
        sessionStorage.removeItem('userInfo');
        //  return <Navigate to='/'/>
    }

    return(
        <>
        <div className={style.dropdown}>
            <div className={style.dropdown_btn} onClick={(e)=>setIsActive(!isActive)}>{userData.name? userData.name : ''}</div>
                {isActive && (
                <div className={style.dropdown_content}>
                    <div className={style.dropdown_item}><button className={style.viewProfile} onClick={toMyprofile}>View Profile</button></div>
                    <div className={style.dropdown_item }><p className={style.logout_btn} onClick={forLogout}>Logout</p></div>
                </div>
                )}
        </div>
        </>
    )
}

export default Dropdown;