import home from "./Home.module.css";
import { FcCalendar, FcPicture, FcDataRecovery } from "react-icons/fc";
import { IoCaretForwardCircleOutline } from "react-icons/io5";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { FiSend } from "react-icons/fi";
import NormalLayout from "../../shared/layouts/NormalLayout/NormalLayout";
import UserCard from "../../shared/components/UserCard/UserCard";
import ModalPopup from "../../shared/components/ModalPop-up/ModalPopup";
import { useState, useEffect } from "react";
import { IMAGEURL } from "../../utils/constants";
import anonymousImage from "../../assets/images/logos/anonymous.webp";
import axios from "axios";
import {  GET_OWN_PROFILE  } from '../../services/ApiConfigs';
import { addNewPostApi ,getAllPostApi} from "../../services/AddPost/AddPost";
import ReactReadMoreReadLess from "react-read-more-read-less";



function HomePage() {
  const [openModal, setOpenModal] = useState(false);
  const [userData,setUserData] = useState(null);
  const [userPost, setUserPost] = useState([]);

  function openPopup() {
    setOpenModal(true);
  }

//   getOwnProfileData();
const getOwnProfileData=async  ()=> {
  let token = localStorage.getItem("token");
  const config = {
        headers: { Authorization: `Bearer ${token}` },
  };
  console.log('token' , config);
  await axios.get(GET_OWN_PROFILE,config)
    .then((result) => {
      // console.log(result.data);
      sessionStorage.setItem("userInfo", JSON.stringify(result.data.data));
      setUserData(result.data.data)
    })
    .catch((error) => {
      console.log(error);
    });
}
function getPostData(){
  getAllPostApi().then((response)=>{
    console.log(response)
    setUserPost(response.data)
  }).catch((error)=>{
    console.log(error);
  })
}
useEffect(() => {
  getOwnProfileData();
  getPostData();
},[]);
  

// useEffect(()=>{
//   getPostData();
// },[])

  return (
    <NormalLayout>
      {userData&&<section>
        <div className={home.flex_one} id="section_div">
          <UserCard />
        </div>
        <div className={home.flex_two} id="section_div">
          <div className={home.userPostSection}>
            <div className={home.post_header}>
              <div>
                <img
                  src={
                    userData?.profilePic?.path
                      ? `${IMAGEURL}/${userData?.profilePic?.path}`
                      : anonymousImage
                  }
                  alt="profiles"
                  className={home.postProfile}
                />
              </div>
              <div className={home.postButton}>
                <button className={home.postBtn} onClick={openPopup}>
                  Start a Post
                </button>
                {openModal && <ModalPopup closeModal={setOpenModal} userPost={getPostData} />}
              </div>
            </div>
            <div className={home.userPost_icon}>
              <div className={home.icon_div}> 
                <FcPicture className={home.icons} />
                Photo
              </div>
              <div className={home.icon_div}>
                <IoCaretForwardCircleOutline className={home.icons} />
                Video
              </div>
              <div className={home.icon_div}>
                <FcCalendar className={home.icons} />
                Events
              </div>
              <div className={home.icon_div}>
                <FcDataRecovery className={home.icons} />
                Write article
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          {
            userPost.map((postData)=>{
              return(
                <div className={home.userFeedsection} key={postData._id}>
            <div className={home.userFeed_profile}>
              <div>
                <img
                  src={
                    userData?.profilePic?.path
                      ? `${IMAGEURL}/${userData?.profilePic?.path}`
                      : anonymousImage
                  }
                  alt="profile"
                  className={home.feed_profile}
                />
              </div>
              <div>
                <p>
                  <b>{postData?.createdBy?.name}</b>
                </p>
                {/* <p className={home.feedProfileDetails}>
                                    Javascript Trainee at Broadifi Technologies. Interest in React Js
                                    || JavaScript || HTML || CSS || Bootstrap
                                </p> */}
                <p className={home.feedProfileDetails}>
                  { userData?.positions[0]?.title }
                  {userData?.positions[0]?.companyName}
                </p>
              </div>
            </div>
            <div className={home.feedtext}><ReactReadMoreReadLess
                                                charLimit={200}
                                                readMoreText={"see more"}
                                                readLessText={"...see less"}
                                                readMoreClassName={home.read_more_less_more}
                                                readLessClassName={home.read_more_less_less}
                                              >
                                                {postData.title}
                                              </ReactReadMoreReadLess>
                                              </div>
            <div>
              <img
                src={postData.multiMedias[0].path? `${IMAGEURL}/${postData.multiMedias[0].path}`:anonymousImage}
                alt="feed"
                className={home.feed_postImage}
              />
            </div>
            <div className={home.feedDownLine}></div>
            <div className={home.comment_div}>
              <div className={home.commentSection}>
                <AiOutlineLike className={home.commentSection_icon} />
                Like
              </div>
              <div className={home.commentSection}>
                <FaRegCommentDots className={home.commentSection_icon} />
                Comments
              </div>
              <div className={home.commentSection}>
                <RiShareForwardLine className={home.commentSection_icon} />
                Share
              </div>
              <div className={home.commentSection}>
                <FiSend className={home.commentSection_icon} />
                Send
              </div>
            </div>
          </div>
              )
            })
          }
          
        </div>
        {/* <div className={home.flex_three} id='section_div'>
                </div> */}
      </section>}
    </NormalLayout>
  );
}
export default HomePage;
