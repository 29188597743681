import axios from "axios";
import { ACCEPT_FRIEND_REQUEST } from "../ApiConfigs";

let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
};

export const acceptFriendRequestApi = async(payload)=>{
    const response = await axios.post(ACCEPT_FRIEND_REQUEST,payload,config);
    return response.data
}

