import style from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState,useEffect} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postLoginApi } from "../../../services/Login/login.api";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  // const [modal, setModal] = useState(false);
  let navigate = useNavigate();
  function gotoRegister() {
    navigate("./register");
  }
    useEffect(()=>{
        if(localStorage.getItem("token")){
          window.location.href = "/home";
        }
    },[])
  const {
    register,
    handleSubmit
  } = useForm();
  const onSubmit = (loginData) => {
    // console.log(loginData);
    setIsLoading(true);
    postLoginApi({ email: loginData.email, password: loginData.password })
      .then((result) => {
        localStorage.setItem("token", result.data.token)
        sessionStorage.setItem('userInfo',JSON.stringify(result.data));
        toast.success("Successfull Login");
        // setTimeout(()=>{
          navigate("/home");
        // },4000)
        // console.log(result);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        // alert("Invalid User");
        toast.error('Invalid User', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      });
  };

  

  return (
    <>
      
      <div className={style.container}>
        <div className={style.main_container}>
         <div className={style.left_child}></div>
         <form className={style.right_child} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.header}>
            <h1 className={style.header_text}>Welcome back</h1>
            <p>Thank you for getting back. please login to your<br/> 
            account by filling these form</p>
            </div> 
              <div className={style.form_container}>
                        <input 
                          type="text"
                          placeholder="Email Address"
                          name="email"
                          {...register("email", { required: "Email is Required" })}
                          className={style.input_field}
                        />
                        <br/>
                        <br/>
                        <input
                          type="password"
                          placeholder="Password"
                          name="paasword"
                          {...register("password", {required: "Password is Required",})}
                          className={style.input_field}
                        />
              </div>
                  <div className={style.loginSection}>
                  {!isLoading && <button className={style.loginBtn} >LOGIN</button>}
                {isLoading && (
                  <button className={style.loginBtn}  disabled>
                    <i className="fa fa-spinner fa-spin"></i>Loading..
                  </button>
                )}
                  </div>
                  <div className={style.registerLinkSection}>
                  Not registered?<p className={style.signLink} onClick={gotoRegister}>Register Now</p>
                  </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Login;
