import React from 'react';
import { useNavigate} from 'react-router-dom';
import styles from './MyProfile.module.css';
import { MdModeEdit } from "react-icons/md";
import { GrAdd } from "react-icons/gr";
import {  IMAGEURL  } from '../../../utils/constants/index'
import anonymousImage from '../../../assets/images/logos/anonymous.webp';
import NormalLayout from '../../../shared/layouts/NormalLayout/NormalLayout';





function MyProfile(){
  
    // const [userData,setUserData] = useState([]);
    const userData = JSON.parse(sessionStorage.getItem('userInfo'));
    const totalConnections = JSON.parse(sessionStorage.getItem('myConnection'));
    // console.log(totalConnections);
    let navigate = useNavigate();
    
    const forConnection=()=>{
        navigate('/myconnections');
    }
 // new Date().toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}), // 08/19/2020 (month and day with two digits)




    return(
        <NormalLayout>
        {/* <Header /> */}
        { userData &&
        
        <div className={styles.container}>
            <div className={styles.flex_one}>
                <div className={styles.myProfileCard}>
                    <div className={styles.myprofileCard}>
                        <img src={userData?.coverPic?.path? `${IMAGEURL}/${userData?.coverPic?.path}`:anonymousImage} alt='pic' className={styles.backgorundProfile}  />
                        <div className={styles.userprofile}>
                        <img src={userData?.profilePic?.path? `${IMAGEURL}/${userData?.profilePic?.path}`:anonymousImage} alt='pic' className={styles.myprofile} />
                        </div>
                    </div>
                    <div className={styles.myProfileBio}>
                       <div>
                        <h2 className={styles.profileName}>{userData?.name && userData?.name}</h2>
                        <p className={styles.profileBio}>{userData?.positions[0]?.title && userData?.positions[0]?.title} at   {userData?.positions[0]?.industry && userData?.positions[0]?.industry} Company</p>
                        <p className={styles.profileBio}>{userData?.positions[0]?.companyName && userData?.positions[0]?.companyName}</p>
                        <p className={styles.profileBio}>Address : {userData?.address && userData?.address}</p>
                        <p className={styles.profileBio}>Email : {userData?.email && userData?.email}</p>
                        <p className={styles.connectionsInfo} onClick={forConnection}>{totalConnections} connections</p>
                       </div>
                       <div>
                            <MdModeEdit className={styles.editIcon}/>
                        </div>
                    </div>  
                </div>
            </div>
            <div className={styles.flex_two}>
                <div className={styles.experienceHeader}>
                    <h3>Experience</h3>
                    <div className={styles.iconSection}>
                    <GrAdd className={styles.editIcon}/>
                    <MdModeEdit className={styles.editIcon}/>
                    </div>
                    </div>
                    <div className={styles.experience}>
                        <img src='https://pbs.twimg.com/profile_images/1560276301411663872/2cl4ffg4_400x400.jpg' alt='pic' className={styles.experienceLogo} />
                        <p className={styles.experiencePara}>
                            <b>{userData?.positions[0]?.title && userData?.positions[0]?.title} at   {userData?.positions[0]?.industry && userData?.positions[0]?.industry} Company</b>
                            {userData?.positions[0]?.companyName && userData?.positions[0]?.companyName}<br/>
                            {userData?.positions[0]?.startDate && userData?.positions[0]?.startDate}  -   {userData?.positions[0]?.endDate && userData?.positions[0]?.endDate}<br/>
                            {userData?.positions[0]?.location && userData?.positions[0]?.location}</p>
                    </div>
            </div>
            <div className={styles.flex_three}>
                <div className={styles.experienceHeader}>
                    <h3>Education</h3>
                    <div className={styles.iconSection}>
                        <GrAdd className={styles.editIcon}/>
                        <MdModeEdit className={styles.editIcon}/>
                    </div>
                    </div>
                    <div className={styles.experience}>
                        <img src='https://pbs.twimg.com/profile_images/1560276301411663872/2cl4ffg4_400x400.jpg' alt='pic' className={styles.experienceLogo} />
                        <p className={styles.experiencePara}>
                            <b><b>{userData?.educations[0]?.institute && userData?.educations[0]?.institute}</b></b>
                            {userData?.educations[0]?.degree && userData?.educations[0]?.degree}<br/>
                            {userData?.educations[0]?.startDate && userData?.educations[0]?.startDate}  -   {userData?.educations[0]?.endDate && userData?.educations[0]?.endDate}<br/>
                            Extra Curriculam : {userData?.educations[0]?.activitesAndSocieties && userData?.educations[0]?.activitesAndSocieties}</p>
                    </div>
            </div>
            <div className={styles.flex_four}>
            <div className={styles.experienceHeader}>
                    <h3>Skills</h3>
                    <div className={styles.iconSection}>
                        <GrAdd className={styles.editIcon}/>
                        <MdModeEdit className={styles.editIcon}/>
                    </div>
                    </div>
                    <div className={styles.skillContent}>
                        <h4>{userData?.skills[0] && userData?.skills[0]}</h4>
                    </div>
                    <hr className={styles.line}/>
                    <div className={styles.skillContent}>
                    <h4>{userData?.skills[1] && userData?.skills[1]}</h4>
                    </div>
                    <hr className={styles.line}/>
            </div>
        </div>}
        </NormalLayout>
    )
}
export default MyProfile;