import NormalLayout from "../../../shared/layouts/NormalLayout/NormalLayout";






function Notifications(){
    return(
    
        <NormalLayout>
        <h2>Notifications Page</h2>
        </NormalLayout>
    )
}
export default Notifications;