
import main from "./Main.module.css";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { FiSend } from "react-icons/fi";
import PerssonalDetails from '../components/PersonalDetails/PersonalDetails';
import UserPostSections from '../components/UserPostSection/UserPostSecions';




function MainContainer() {
  return (
    <>
    <PerssonalDetails/>
         <UserPostSections/>
      <div className={main.container}>
        <div className={main.header_line}></div>
        <div className={main.child_container}>
          <div className={main.feed_profileSection}>
            <div>
              <img
                src="https://c4.wallpaperflare.com/wallpaper/44/207/612/computer-glasses-macbook-wallpaper-preview.jpg"
                alt="profile"
                className={main.feed_profile}
              />
            </div>
            <div>
              <p>
                <b>Pravasini Bhuyan</b>
              </p>
              <p>
                Javascript Trainee at Broadifi Technologies. Interest in React Js
                || JavaScript || HTML || CSS || Bootstrap
              </p>
            </div>
          </div>
          <div className={main.userPost}>
            <div>
                <img src='https://images.unsplash.com/photo-1551499779-ee50f1aa4d25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8N3x8fGVufDB8fHx8&w=1000&q=80' alt="feed" className={main.feed_postImage} />
            </div><br/>
            <div className={main.feed_downLine}></div>
                <div className={main.comment_div}>
                    <div className={main.commentSection}><AiOutlineLike/>Like</div>
                    <div className={main.commentSection}><FaRegCommentDots/>Comments</div>
                    <div className={main.commentSection}><RiShareForwardLine/>Share</div>
                    <div className={main.commentSection}><FiSend/>Send</div>
                </div>

          </div>
        </div>
      </div>
    </>
  );
}
export default MainContainer;
