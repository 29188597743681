import axios from "axios";
import { GET_SINGLE_USER } from "../ApiConfigs";

let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
};


 export const getSingleUserApi = async(extra)=>{
    // console.log('hello', extra)
    // console.log('id:' ,GET_SINGLE_USER + extra._id)
    const response = await axios.get(GET_SINGLE_USER+extra._id,config)
    return response.data;

}
// export default getSingleUserApi;