import React from 'react';
import Header from '../../../pages/Component/Header';

const NormalLayout=(props)=>{
    return(
        <>
        <Header/>
        {props.children}
        </>
    )

}

export default NormalLayout;