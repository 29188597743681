import axios from "axios";
import { ADD_POST,MEDIA_POST ,GET_ALL_POST,GET_ALL_POST_BY_USER} from "../ApiConfigs";



let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
};
export const mediaApi = async(file)=>{
    const response = await axios.post(MEDIA_POST,file,config)
    return response.data
}



export const addPostApi = async(payload)=>{
    const response = await axios.post(ADD_POST,payload,{...config})
    // console.log('token:', config)
    return response.data;
}


export const addNewPostApi = async()=>{
    const response = await axios.get(GET_ALL_POST,{...config})
    return response.data;
}


export const getAllPostApi = async()=>{
    let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
};
       console.log('token:',config)
    const response = await axios.get(GET_ALL_POST_BY_USER,{...config})
  
    return response.data;
}
