import axios from "axios";
import { FRIEND_REQUEST_URL } from "../ApiConfigs";

let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

export const friendRequestApi = async()=>{
    const response = await axios.get(FRIEND_REQUEST_URL,config);
    return response.data
}

