import React from 'react';
import styles from './UserCard.module.css'
import { BsFillBookmarkFill } from "react-icons/bs";
import { IMAGEURL } from '../../../utils/constants';
import anonymousImage from '../../../assets/images/logos/anonymous.webp'

const UserCard=()=>{
    const userData = JSON.parse(sessionStorage.getItem('userInfo'));
    // console.log(userData);
return(
  <>
                    <div className={styles.usercard_background}>
                        <img src={userData?.coverPic?.path? `${IMAGEURL}/${userData?.coverPic?.path}`:anonymousImage} alt='backgournd' className={styles.background_profile} />
                        <div className={styles.userProfile} >
                            <img src={userData?.profilePic?.path? `${IMAGEURL}/${userData?.profilePic?.path}`:anonymousImage } alt='profiles' className={styles.profile} />
                        </div>
                    </div>
                    <div className={styles.profile_detials}>
                    <b>{userData?.name? userData?.name : '' }</b>
                    <p className={styles.UserCard_para}>{userData?.positions[0]?.title && userData?.positions[0]?.title} at {userData?.positions[0]?.companyName && userData?.positions[0]?.companyName}
                    <br/>
                    Skills {userData.skills && userData.skills}
                    </p>
                    <b>More Details</b>
                    <p className={styles.UserCard_para}>{userData.headlin  && userData.headline}
                        {userData?.educations[0]?.activitesAndSocieties && userData?.educations[0]?.activitesAndSocieties}</p>
                        <p className={styles.UserCard_para2}><b><BsFillBookmarkFill/> My Items</b></p>
                </div>
    </>
)
}
export default UserCard