import React,{useState,useEffect} from "react";
import styles from './FriendProfile.module.css';
import NormalLayout from "../../shared/layouts/NormalLayout/NormalLayout";
import { MdModeEdit } from "react-icons/md";
import { GrAdd } from "react-icons/gr";
import { IMAGEURL } from "../../utils/constants";
import anonymousImage from '../../assets/images/logos/anonymous.webp'
import  {getSingleUserApi}  from '../../services/GetSingleUserData/GetSingleUserData';
import {useLocation} from 'react-router-dom';





function FriendProfile(){
    const [ friendData, setFriendData] = useState();

    const location = useLocation();
    // console.log('hii', location.state)

    function friendProfileData(){
            getSingleUserApi({_id:`${location.state._id}`})

            .then((resp)=>{
                // console.log(resp.data)
                setFriendData(resp.data)
            }).catch((err)=>{
                console.log(err);
            })
        }

        useEffect(()=>{
            friendProfileData();
        },[]);

        // console.log(friendData);
    return(
        <NormalLayout>
            { friendData && <div className={styles.container}>
            <div className={styles.flex_one}>
                <div className={styles.myProfileCard}>
                    <div className={styles.myprofileCard}>
                        <img src={friendData?.coverPic?.path? `${IMAGEURL}/${friendData?.coverPic?.path}`:anonymousImage} alt='pic' className={styles.backgorundProfile}  />
                        <div className={styles.userprofile}>
                        <img src={friendData?.profilePic?.path? `${IMAGEURL}/${friendData?.profilePic?.path}`:anonymousImage} alt='pic' className={styles.myprofile} />
                        </div>
                    </div>
                    <div className={styles.myProfileBio}>
                       <div>
                        <h2 className={styles.profileName}>{friendData?.name && friendData.name}</h2>
                        <p className={styles.profileBio}>{friendData?.positions[0].title} at  Company {friendData?.positions[0].industry}</p>
                        <p className={styles.profileBio}>{friendData?.positions[0].companyName}</p>
                        <p className={styles.profileBio}>Address : {friendData?.address && friendData?.address}</p>
                        <p className={styles.profileBio}>Email : {friendData.email}</p>
                        <p className={styles.connectionsInfo}>Connections</p>
                       </div>
                       <div>
                            <MdModeEdit className={styles.editIcon}/>
                        </div>
                    </div>  
                </div>
            </div>
            <div className={styles.flex_two}>
                <div className={styles.experienceHeader}>
                    <h3>Experience</h3>
                    <div className={styles.iconSection}>
                    <GrAdd className={styles.editIcon}/>
                    <MdModeEdit className={styles.editIcon}/>
                    </div>
                    </div>
                    <div className={styles.experience}>
                        <img src='https://pbs.twimg.com/profile_images/1560276301411663872/2cl4ffg4_400x400.jpg' alt='pic' className={styles.experienceLogo} />
                        <p className={styles.experiencePara}>
                            <b>{friendData?.positions[0]?.title} at   Company {friendData?.positions[0]?.industry}</b>
                            <br/>
                            {friendData?.positions[0]?.companyName}<br/>
                            {friendData?.positions[0]?.startDate} -  {friendData?.positions[0]?.endDate} <br/>
                            </p>
                    </div>
            </div>
            <div className={styles.flex_three}>
                <div className={styles.experienceHeader}>
                    <h3>Education</h3>
                    <div className={styles.iconSection}>
                        <GrAdd className={styles.editIcon}/>
                        <MdModeEdit className={styles.editIcon}/>
                    </div>
                    </div>
                    <div className={styles.experience}>
                        <img src='https://pbs.twimg.com/profile_images/1560276301411663872/2cl4ffg4_400x400.jpg' alt='pic' className={styles.experienceLogo} />
                        <p className={styles.experiencePara}>
                            <b>{friendData.educations[0]?.degree}</b><br/>
                            <b>{friendData.educations[0]?.institute}</b>
                            <br/>
                            {friendData?.educations[0]?.startDate}  -   {friendData?.educations[0]?.endDate}  <br/>
                            Extra Curriculam : {friendData?.educations[0]?.activitesAndSocieties} </p>
                    </div>
            </div>
            <div className={styles.flex_four}>
            <div className={styles.experienceHeader}>
                    <h3>Skills</h3>
                    <div className={styles.iconSection}>
                        <GrAdd className={styles.editIcon}/>
                        <MdModeEdit className={styles.editIcon}/>
                    </div>
                    </div>
                    <div className={styles.skillContent}>
                        <h4>{friendData?.skills[0]} </h4>
                    </div>
                    <hr className={styles.line}/>
                    <div className={styles.skillContent}>
                    <h4>{friendData?.skills[1]}</h4>
                    </div>
                    <hr className={styles.line}/>
            </div>
        </div>}

        </NormalLayout>
    )
}

export default FriendProfile;


// 6317474b98623a028d3b93ba