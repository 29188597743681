


function Protected(props){
    let token = localStorage.getItem('token');
    if(token === null){
        window.location.href=('/');
    }
    return props.children
}
export default Protected;
