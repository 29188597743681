import style from './MyNetwork.module.css';
import React,{useEffect, useState} from "react";
import { friendRequestApi } from "../../../services/UserFriendRequest/UserFriendRequest";
import { IMAGEURL } from "../../../utils/constants";
import { acceptFriendRequestApi } from "../../../services/AcceptFriendRequest/AceeptFriendRequest";
import NormalLayout from "../../../shared/layouts/NormalLayout/NormalLayout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDone } from "react-icons/md";
import { ignoreFriendRequestApi } from "../../../services/IgnoreFriendRequest/IngoreFriendRequestApi";
import {useNavigate} from 'react-router-dom';
import { getAllUserApi } from "../../../services/GetAllUser/GetAllUser";
import anonymousImage from '../../../assets/images/logos/anonymous.webp';
import { sentFriendRequestApi } from "../../../services/SentfriendRequest/SentFriendRequest";


function Mynetwork(){
        const [userFriendData,setUserFriendData] = useState([]);
        const [pendingList, setPendingList] = useState(2);
        const [suggestFriend,setSuggestFriend] = useState([]);
        const [isPendilng,setIsPending] = useState(false);
        const[diableBtn,setDisableBtn] = useState(-1);
        let navigate = useNavigate();

        function getUserFriendRequest(){
            friendRequestApi().then((result)=>{
                // console.log(result.data.receivedFriendRequests[0]);
                setUserFriendData(result.data.receivedFriendRequests);
            }).catch((error)=>{
                console.log(error);
            })
        }
        useEffect(()=>{
            getUserFriendRequest();
            // console.log('hii')
        },[]);
    
            // console.log(IMAGEURL);
        function acceptRequest(_id){
            acceptFriendRequestApi({_id:`${_id}`}).then((result)=>{
                // console.log(result);
                toast.success("Added Successfully", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                getUserFriendRequest();
            }).catch((error)=>{
                console.log(error);
            })
        }
        // useEffect(()=>{
        //     acceptRequest();
        //     getUserFriendRequest();

        // },[])
        // acceptRequest();
        // console.log(userFriendData[0]._id);

        function ignoreRequest(_id){
            ignoreFriendRequestApi({_id:`${_id}`})
            .then((result)=>{
                // console.log(result);
                getUserFriendRequest();
            }).catch((err)=>{
                console.log(err);
            })
        }
        function viewProfile(_id){
            navigate('/friend-profile',{state:{_id}});
            // console.log(_id)
        }

        function getAllUser(){
            getAllUserApi()
            .then((resp)=>{
                // console.log(resp.data);
                setSuggestFriend(resp.data);
            }).catch((error)=>{
                console.log(error);
            })
        }
        
        useEffect(()=>{
            getAllUser()
        },[])

        function toFollow(_id,index){
            sentFriendRequestApi({_id:`${_id}`})
            .then((result)=>{
                console.log(result);
                setIsPending(true);
                setDisableBtn(index)
            }).catch((error)=>{
                console.log(error);
            })

        }
    return(
        <NormalLayout>
        
            <div className={style.sections}>
                    <div className={style.flex_one}>
                    <div className={style.header}>
                        {
                            userFriendData.length ? <p>Invitations</p>:<p>No pendilng Invitations</p>
                        }
                        <p>Manage</p>
                    </div>
                        <ul>
                            {
                                userFriendData.length !== 0 && (
                                    <>
                                    {userFriendData.map((userData , index)=>{
                                        return(
                                            index < pendingList &&(
                                                <li className={style.requestSectionli} key={userData?._id}>
                                                <div className={style.requestSection}>
                                                <img src={`${IMAGEURL}/${userData?.profilePic?.path}`} alt="pic" className={style.requsetProfile} />
                                                <div> 
                                                    <h4 className={style.requestUserName} onClick={()=>viewProfile(userData._id)}>{userData?.name}</h4>
                                                    <p className={style.user_text}>{userData?.positions[0]?.title} </p>
                                                    <p className={style.user_text}>{userData?.positions[0]?.companyName}</p>
                                                </div>
                                                </div>
                                                <div className={style.requestAcceptSection}>
                                                    <h5 className={style.ignoreBtn} onClick={()=> ignoreRequest(userData._id)}>Ignore</h5>
                                                    <button className={style.acceptBtn} onClick={()=> acceptRequest(userData._id)}>Accept</button>
                                                </div>
                                            </li>
                                            )
                                        );
                                    })}
                                        {
                                        pendingList !== userFriendData.length && (
                                            <div className={style.viewMoreFriendRequest} onClick={()=>setPendingList(userFriendData.length)}><p>See More</p></div>
                                        )}
                                    </>
                                )}
                        </ul>
                        
                    </div>
                    <div className={style.flex_tow}>
                        <div className={style.flex_tow_header}>
                            <p>People you know</p>
                        </div>
                        <div className={style.newUserCard} >
                            {
                                suggestFriend.map((newData,index)=>{
                                    return(
                                        <div className={style.containerCards} key={newData._id}>
                                            <div className={style.header_profileCard}>
                                                <img src={newData?.profilePic?.path? `${IMAGEURL}/${newData?.profilePic?.path}`:anonymousImage} alt='pic' className={style.header_profile} />
                                            <div className={style.NewUserCard_profilesection}>
                                                <img src={newData?.profilePic?.path? `${IMAGEURL}/${newData?.profilePic?.path}`:anonymousImage} alt='pic' className={style.NewUserCard_profile} />
                                            </div>
                                            <div className={style.NewUserCard_profileDetails}>
                                                    <h2 className={style.NewUserCardName}>{newData.name}</h2>
                                                    <p className={style.NewUserCardBio}>{newData?.skills[0]}</p>
                                                      <p>{newData?.address}</p>
                                            </div>
                                            </div>
                                            <div className={style.followSection}>
                                                 <button className={style.followBtn} onClick={()=>toFollow(newData._id,index)} disabled={setDisableBtn === index}>
                                                 {isPendilng? 'Follow':'Pendilng'}
                                                 </button>
                                            </div>
                                        </div>
                                    )})
                            }
                            
                        </div>
                    </div>
            </div>
        </NormalLayout>
    )
}
export default Mynetwork;

















