import axios from "axios";
import { REGISTER_URL } from "../ApiConfigs";


export const postRegisterApi = async(payload)=>{
    const response = await axios.post(REGISTER_URL,payload);
    return response.data
}

// postRegister({
//     email: data.email,
//     password: data.password,
//     name: data.name,
//     mobile: data.phone,
//   })