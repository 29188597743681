import "./App.css";
import React, { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/auth/Register/Register";
import Login from "./pages/auth/Login/Login";
import { ToastContainer } from "react-toastify";
import Navbar from "./pages/Component/Header";
import MainContainer from './shared/Container/MainContainer';
import About from "./pages/About/Component/About";
// import MyNetwork from "./pages/MyNetwork/Mynetwork";
// import Notifications from "./pages/Notifications/Notifications";
// import Mynetwork from "./pages/MyNetwork/Mynetwork";
// import PersonalDetails from "./shared/components/UserProfile/PersonalDetails/PersonalDetails";
import HomePage from "./pages/Component/HomePage";
import Mynetwork from "./pages/MyNetwork/Component/Mynetwork";
import Notifications from "./pages/Notifications/Component/Notifications";
// import Mynetwork from "./pages/App/Screens/MyNetwork/Mynetwork";
import DropdownButton from './shared/components/DropdownButton/DropdownButton';
import NewUserCard from "./shared/components/NewUserCard/NewUserCard";
import MyProfile from "./pages/MyProfile/Component/MyProfile";
import NormalLayout from "./shared/layouts/NormalLayout/NormalLayout";
import MyConnections from './pages/MyConnections/Component/MyConnections'
import Protected from "./utils/protectedRoute/ProtectedRoute";
import FriendProfile from "./pages/FriendProfile/FriendProfile";
import ImageModal from "./shared/components/ImageModal/ImageModal";



function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer
         position="top-center"
         autoClose={2000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         rtl={false}
         pauseOnFocusLoss
         draggable
         pauseOnHover
        />
        <Routes>
          <Route path="/home" element={<Protected><HomePage/></Protected>}/>
          <Route path="/about" element={<Protected><About/></Protected>}/>
          <Route path="/mynetwork" element={<Protected><Mynetwork/></Protected>}/>
          <Route path="/notifications" element={<Protected><Notifications/></Protected>}/>
          <Route path="/my-profile" element={<Protected><MyProfile/></Protected>} />
          <Route path='/myconnections' element={<Protected><MyConnections/></Protected>} />
          <Route path="/friend-profile" element={<Protected><FriendProfile/></Protected>}/>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </BrowserRouter>

{/* <DropdownButton/> */}
      {/* <UserPostSections/> */}
      {/* <PersonalDetails/> */}
      {/* <MainContainer/> */}
      {/* <Home/> */}
      {/* <HomePage/> */}
      {/* <NewUserCard/> */}
      {/* <ImageModal/> */}

    </>
  );
}

export default App;
