import axios from "axios";
import { FRIEND_LIST } from "../ApiConfigs";

let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
};

export const friendListApi =async()=>{
    const response = await axios.get(FRIEND_LIST,config);
    return response.data
}
