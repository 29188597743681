import axios from "axios";
import { SENT_FRIEND_REQUEST } from "../ApiConfigs";

let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
};

export const sentFriendRequestApi = async(payload)=>{
    console.log('id' ,payload)
    const response = await axios.post(SENT_FRIEND_REQUEST,payload,config);
    return response.data;
}