import axios from "axios";
import { GET_ALL_USER } from "../ApiConfigs";


let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
};


export const getAllUserApi =async()=>{
    const response = await axios.get(GET_ALL_USER,config);
    return response.data;
}

