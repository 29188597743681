
    // This is Base URL //

export const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log('data', BASE_URL);

    // User Register Api //

export const REGISTER_URL = `${BASE_URL}/api/auth/register`;

    // User Login //

export const LOGIN_URL= `${BASE_URL}/api/auth/login`;

    // UserFriend Request //

export const FRIEND_REQUEST_URL = `${BASE_URL}/api/users/received-friend-request`;

    // UserFriend List //

export const FRIEND_LIST = `${BASE_URL}/api/users/all-friends`;


    // export const IMAGEURL = process.env.MEDIA_BASE_URL;

    // Accept Friend Request //

export const ACCEPT_FRIEND_REQUEST = `${BASE_URL}/api/users/accept`;


    // Get Own Profile //

export const GET_OWN_PROFILE = `${BASE_URL}/api/auth/profile`;

    // Ignore Friend Request //

export const iGNORE_FRIEND_REQUEST = `${BASE_URL}/api/users/reject`;

    // Get Single User-Data //

export const GET_SINGLE_USER = `${BASE_URL}/api/users/user/`;

    // suggest(Get) All User //

export const GET_ALL_USER = `${BASE_URL}/api/users/`;

    // Sent Friend Request //

export const SENT_FRIEND_REQUEST = `${BASE_URL}/api/users/send-friend-request`;

    // Add Post //

export const ADD_POST = `${BASE_URL}/api/posts/`;

    // Insert Media //
    
export const MEDIA_POST = `${BASE_URL}/api/media/`;

    // Get All Post //

export const GET_ALL_POST = `${BASE_URL}/api/posts/`;

    // Get All Post By User //

export const GET_ALL_POST_BY_USER = `${BASE_URL}/api/posts/all-posts-by-user`;