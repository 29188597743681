import React,{useState} from 'react';
import modal from './ModalPopup.module.css'
import { GrClose } from "react-icons/gr";
import { IMAGEURL } from '../../../utils/constants';
import anonymousImage from '../../../assets/images/logos/anonymous.webp';
import { addPostApi,mediaApi } from '../../../services/AddPost/AddPost';
import { BsFillImageFill,BsFillPlayBtnFill } from "react-icons/bs";





const ModalPopup =({closeModal,userPost})=>{
    const [openImageModal,setOpenImageModal] = useState(false);
    const [imageFile,setImageFile] = useState('');
    const [editorText,setEditorText] = useState('');
    const [imageId,setImageId] = useState('');
    const userData = JSON.parse(sessionStorage.getItem('userInfo'));
    // const [videoLink,setVideoLink] = useState('');
  function forCloseModal(){
    closeModal(false);
  }
//   function openImagePopup(){
//     // closeModal(false)
//     setOpenImageModal(true)
//   }

  function handleChange(e){
    // console.log(e.target.files[0])
    setImageFile(e.target.files[0]);
  } 
  function uploadImage(e){
        let formData = new FormData();
        formData.append('file',imageFile);
      //   console.log(formData)
        mediaApi(formData)
          .then((res)=>{
            //   console.log(res.data._id);
            setImageId(res.data._id)
            // console.log(imageId);
          }).catch((err)=>{
              console.log(err)
          })
    // }
  } 
 
  const onSubmit = ()=>{
    // const postData = {imageFile,editorText}
   addPostApi({
    title:editorText,
    multiMedias:imageId
})
   .then((result)=>{
    closeModal(false)
    userPost();
    console.log(result);
   }).catch((error)=>{
    console.log(error)
   })

}
//   function videoHandleChange(e){
//     console.log('video' ,e.target.file[0])
//     const video = e.target.files[0];
//   }

    return(
        <>
        <div className={modal.modalBackground}>
            <div className={modal.modalContainer}>
                <div className={modal.title}>
                    <div><b>Create a post</b></div>
                    <div> <GrClose className={modal.modalCloseBtn} onClick={forCloseModal}/></div>
                </div>
                <div className={modal.body}>
                    <div className={modal.modalProfile}>
                    <img src={userData?.profilePic?.path? `${IMAGEURL}/${userData?.profilePic?.path}`:anonymousImage} alt='profiles' className={modal.ProfileImage} />
                    <b>{userData.name}</b>
                    </div>
                    <div className={modal.textInput}>
                       <textarea value={editorText} onChange={(e)=>setEditorText(e.target.value)} placeholder="Share your thoughts..." className={modal.modalTextarea}></textarea>
                    </div>
                    <div className={modal.uploadImage}>
                    {imageFile && <img src={URL.createObjectURL(imageFile)} className={modal.image} alt='pic'/>}
                    
                    </div>
                    
                    <div className={modal.hash_div}>
                        <button className={modal.uploadBtn} onClick={uploadImage}>Upload</button>
                    </div>
                </div>
                <div className={modal.footer}>
                    <div className={modal.modalposticon}>
                    <div className={modal.modalposticonDiv}>
                        <label htmlFor='file-input'>
                        <BsFillImageFill className={modal.icons}/>
                    </label>
                    <input id='file-input' type='file' accept='image/gif, image/jpeg, image/png' className={modal.modalInput} onChange={handleChange}/>
                    {/* {openImageModal && <ImageModal openImageModal={setOpenImageModal} imagestate={setImageFile}/>} */}
                    </div>
                    <div className={modal.modalposticonDiv}>
                        <label htmlFor='file'>
                            <BsFillPlayBtnFill className={modal.playIcon}/>
                        </label>
                        {/* <input id='file' type='file' className={modal.modalInput} onChange={handleChange}/> */}
                    </div>
                    {/* <div className={modal.modalposticonDiv}><HiDocumentText className={modal.icons}/></div> */}
                    </div>
                    <div><button className={modal.modalPostBtn} disabled={!editorText? true : false} onClick={onSubmit}>Post</button></div>
                </div>
            </div>
        </div>
        </>
    )
}
export default ModalPopup;