import axios from "axios";
import { iGNORE_FRIEND_REQUEST } from "../ApiConfigs";

let token = localStorage.getItem("token");
const config = {
    headers: { Authorization: `Bearer ${token}` },
}

export const ignoreFriendRequestApi = async(payload)=>{
    const response = await axios.post(iGNORE_FRIEND_REQUEST,payload,config)
    return response.data;
}
